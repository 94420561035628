import { useRouter } from 'next/router'
import { Column, Container, Icon } from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { MyAccountLevel } from 'components/MyAccountLevel'
import { MyAccountBlocks } from 'components/MyAccountBlocks'
import { useMyAccount } from './helpers/useMyAccount'

export const MyAccountPageColors = {
  backgroundColor: getGlobalStyle('--color-base-white'),
  iconColor: getGlobalStyle('--color-neutral-black')
}

export const MyAccountPage = () => {
  const router = useRouter()
  const myAccount = useMyAccount({ screen: 'mobile' })

  const handleIconBackClick = () => {
    router.replace(`${process.env.NEXT_PUBLIC_HOMEURL}`)
  }

  return (
    <Container
      backgroundColor={MyAccountPageColors.backgroundColor}
      flexGrow={1}
      padding='16px'
    >
      <Column gap={24}>
        <Container position='relative'>
          <Container
            clickable='pointer'
            margin='-2px 0 0 -2px'
            onClick={handleIconBackClick}
            position='absolute'
            width='auto'
            zIndex='2'
          >
            <Icon
              clickable='inherit'
              color={MyAccountPageColors.iconColor}
              customSize={24}
              name='Back4'
            />
          </Container>

          <MyAccountLevel
            actions={myAccount.actions?.fields?.items}
            color={myAccount.level?.fields?.backgroundColor}
            fontColor={myAccount.level?.fields?.fontColor}
            isLoading={myAccount.isLoading}
            level={myAccount.level?.fields?.description}
            pictureSrc={myAccount.levelIcon?.fields?.image?.fields?.file?.url}
            userName={myAccount.userFirstName}
          />
        </Container>

        <MyAccountBlocks items={myAccount.blocks} />
      </Column>
    </Container>
  )
}
