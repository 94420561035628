import { IconNames } from '@smu-chile/pkg-unimarc-components'
import { MenuList } from '@smu-chile/pkg-unimarc-components/stories/organisms/MenuList/MenuList'
import {
  isValidArrayWithData,
  Item,
  useSession
} from '@smu-chile/pkg-unimarc-hooks'
import { useRouter } from 'next/router'

export type MyAccountMenuProps = {
  items?: Array<Item>
  title?: string
}

export const MyAccountMenu = ({ items, title }: MyAccountMenuProps) => {
  const router = useRouter()
  const session = useSession({ reactQuery: { enabled: true } })

  const filterByFlow = (item: Item) => {
    if (item.fields?.flow?.includes('logged')) {
      return session.isLoggedIn
    }

    if (item.fields?.flow?.includes('guest')) {
      return session.isLoggedIn === false
    }

    return true
  }

  const filterByNavigation = (item: Item) => {
    return item.fields?.url
  }

  const handleMenuListItemClick = (item: Item) => {
    return () => {
      if (item.fields?.url) {
        router.push(item.fields?.url)
      }
    }
  }

  const mapMenuItem = (item: Item) => {
    return {
      id: item.sys?.id,
      iconName: item.fields?.iconText as IconNames,
      iconPicture: item.fields?.icon?.fields?.file?.url,
      onClick: handleMenuListItemClick(item),
      label: item.fields?.showLabel ? item.fields?.labelNewText : null,
      labelBackgroundColor: item.fields?.labelColorBackground,
      labelColor: item.fields?.labelColorText,
      text: item.fields?.label
    }
  }

  const menuItems = items
    .filter(filterByFlow)
    .filter(filterByNavigation)
    .map(mapMenuItem)

  return (
    <>
      {isValidArrayWithData(menuItems) && (
        <MenuList
          items={menuItems}
          title={title}
        />
      )}
    </>
  )
}
